.table {
  padding: 0px;
  border-radius: 8px;
  border: 1px solid #d8d8d8;
  overflow: hidden;
  width: 100%;
}
.table-footer {
  padding: 10px 5px;
  min-height: 52px;
  background-color: #f5f5f5;
}
.icons .icon {
  background-color: var(--neutrals-white);
  border-radius: 0px;
  border: 1px solid #dee2e6;
}
.icons {
  margin-left: 20px;
}
.icons .icon.first {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.icons .icon.last {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.table-head {
  padding: 30px;
}
.header-tools {
  gap: 20px;
}
.numbers {
  width: 80px;
  white-space: nowrap;
  overflow-x: auto;
}
.numbers .icon {
  display: inline-block;
}
.numbers .icon span {
  width: 100%;
  height: 100%;
  color: var(--yellow-tints-5);
}
.numbers .icon span.active-cell,
.numbers .icon span:hover {
  background-color: var(--yellow-tints-1);
}
.table-head h2 {
  white-space: nowrap;
  overflow: hidden;
  /* text-overflow: ellipsis; 
   max-width: 25%; */
}
.table-footer .data-info {
  /* max-width: 130px; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table-content {
  overflow: hidden;
  white-space: nowrap;
  overflow-x: auto;
}
.view-action svg {
  margin-right: 7px;
}
.view-action {
  background-color: transparent;
  background: none;
  outline: none;
  border: none;
}
.table-image .img-cnt {
  width: 40px;
  height: 40px;
  /* background-color: var(--black-tints-4); */
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
}
.table-image .img-cnt img {
  width: 100%;
  height: 100%;
}
.item-id {
  cursor: pointer;
}
.item-link svg {
  margin-left: 7px;
}
.table-loader {
  height: 100px;
}
/* @media (max-width: 1000px) {
  .header-tools .input-container {
    display: none;
  }
} */
@media (max-width: 540px) {
  .table-head,
  .table-footer {
    flex-direction: column;
    align-items: flex-start;
  }
  .table-footer .data-info {
    margin-bottom: 10px;
    margin-left: 20px;
  }
  .table-head h2 {
    max-width: 100%;
    margin-bottom: 10px;
  }
}

table {
  border-collapse: collapse;
  width: 100%;
}
td,
th {
  border-bottom: 1px solid #dddddd;
  text-align: left;
  padding: 20px 15px;
  white-space: nowrap;
}
th {
  background-color: #f5f5f5;
}
.inner-table td,
.inner-table th {
  border: none;
  padding: 7px;
  white-space: normal;
}
table.pd td,
table.pd th {
  padding: 15px 0px;
}
