@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a {
  text-decoration: none;
}
:root {
  --main-yellow: #f7941d;
  --main-off-black: #1a1918;
  --yellow-tints-1: #fcdbb4;
  --yellow-tints-2: #fab868;
  --yellow-tints-3: #f8a643;
  --yellow-tints-4: #ce7b18;
  --yellow-tints-5: #7b4a0e;
  --yellow-tints-6: #52310a;
  --yellow-tints-7: #311e06;
  --black-tints-1: #d4d4d4;
  --black-tints-2: #808080;
  --black-tints-3: #333333;
  --black-tints-4: #d7d7d7;
  --neutrals-white: #ffffff;
  --neutrals-black: #000000;
  --neutrals-light-yellow: #fdead2;
  --neutrals-lighter-yellow: #fff9f2;
  --alerts-danger: #f44336;
  --alerts-warn: #ff9800;
  --alerts-success: #4caf50;
  --alerts-info: #2196f3;
}
/* Font Weight Utilities */
.f-100 {
  font-weight: 100;
}
.f-200 {
  font-weight: 200;
}
.f-300 {
  font-weight: 300;
}
.f-400 {
  font-weight: 400;
}
.f-500 {
  font-weight: 500;
}
.f-600 {
  font-weight: 600;
}
.f-700 {
  font-weight: 700;
}
.f-800 {
  font-weight: 800;
}
.f-900 {
  font-weight: 900;
}
/* Display Utilities */
.d-none {
  display: none;
}
.d-block {
  display: block;
}
.d-inline {
  display: inline;
}
.d-inline-block {
  display: inline-block;
}
.d-flex {
  display: flex;
}
.d-inline-flex {
  display: inline-flex;
}
.d-grid {
  display: grid;
}
.d-inline-grid {
  display: inline-grid;
}
.d-table {
  display: table;
}
.d-table-row {
  display: table-row;
}
.d-table-cell {
  display: table-cell;
}
.d-table-caption {
  display: table-caption;
}
/* Justify Content Utilities */
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.justify-evenly {
  justify-content: space-evenly;
}

/* Align Items Utilities */
.align-start {
  align-items: flex-start;
}
.align-end {
  align-items: flex-end;
}
.align-center {
  align-items: center;
}
.align-baseline {
  align-items: baseline;
}
.align-stretch {
  align-items: stretch;
}
/* Font Size Utilities */
.fs-08 {
  font-size: 0.5rem;
} /* 8px */
.fs-09 {
  font-size: 0.5625rem;
} /* 9px */
.fs-10 {
  font-size: 0.625rem;
} /* 10px */
.fs-11 {
  font-size: 0.6875rem;
} /* 11px */
.fs-12 {
  font-size: 0.75rem;
} /* 12px */
.fs-13 {
  font-size: 0.8125rem;
} /* 13px */
.fs-14 {
  font-size: 0.875rem;
} /* 14px */
.fs-15 {
  font-size: 0.9375rem;
} /* 15px */
.fs-16 {
  font-size: 1rem;
} /* 16px */
.fs-17 {
  font-size: 1.0625rem;
} /* 17px */
.fs-18 {
  font-size: 1.125rem;
} /* 18px */
.fs-19 {
  font-size: 1.1875rem;
} /* 19px */
.fs-20 {
  font-size: 1.25rem;
} /* 20px */
.fs-21 {
  font-size: 1.3125rem;
} /* 21px */
.fs-22 {
  font-size: 1.375rem;
} /* 22px */
.fs-23 {
  font-size: 1.4375rem;
} /* 23px */
.fs-24 {
  font-size: 1.5rem;
} /* 24px */
.fs-25 {
  font-size: 1.5625rem;
} /* 25px */
.fs-26 {
  font-size: 1.625rem;
} /* 26px */
.fs-27 {
  font-size: 1.6875rem;
} /* 27px */
.fs-28 {
  font-size: 1.75rem;
} /* 28px */
.fs-29 {
  font-size: 1.8125rem;
} /* 29px */
.fs-30 {
  font-size: 1.875rem;
} /* 30px */
.fs-31 {
  font-size: 1.9375rem;
} /* 31px */
.fs-32 {
  font-size: 2rem;
} /* 32px */
.fs-33 {
  font-size: 2.0625rem;
} /* 33px */
.fs-34 {
  font-size: 2.125rem;
} /* 34px */
.fs-35 {
  font-size: 2.1875rem;
} /* 35px */
.fs-36 {
  font-size: 2.25rem;
} /* 36px */

/* fonts */
.inter,
.inter > * {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}
.raleway,
.raleway > * {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}
.status {
  padding: 6px 10px;
  width: fit-content;
  border-radius: 3px;
  text-transform: uppercase;
  color: var(--neutrals-white);
  cursor: pointer;
}
.icon {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
}
.round {
  border-radius: 50%;
}
.br-10 {
  border-radius: 10px;
}
.br-20 {
  border-radius: 20px;
}

.br-b-10 {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.br-t-10 {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.b-10 {
  position: relative;
  bottom: -10px;
}

.p-t-10 {
  padding: 30px;
}

.success {
  background-color: var(--alerts-success);
}
.danger {
  background-color: var(--alerts-danger);
}
.warn {
  background-color: var(--alerts-warn);
}
.info {
  background-color: var(--alerts-info);
}
.status-tint5 {
  background-color: var(--yellow-tints-5);
}
.status-tint5-text {
  color: var(--yellow-tints-5);
}
.status-tint5-light {
  background-color: rgba(123, 74, 14, 0.2);
  border-color: var(--yellow-tints-7);
}
.success-light {
  background-color: #4caf5033;
  border-color: var(--alerts-success);
}
.danger-light {
  background-color: #f4433633;
  border-color: var(--alerts-danger);
}
.warn-light {
  background-color: #ff980033;
  border-color: var(--alerts-warn);
}
.info-light {
  background-color: #2196f333;
  border-color: var(--alerts-info);
}
.disabled,
*:disabled {
  background-color: var(--black-tints-4) !important;
  border-color: var(--black-tints-4) !important;
}
.success-text {
  color: var(--alerts-success) !important;
}
.danger-text {
  color: var(--alerts-danger);
}
.warn-text {
  color: var(--alerts-warn);
}
.info-text {
  color: var(--alerts-info);
}
.text-white {
  color: var(--neutrals-white);
}
.text-grey {
  color: var(--black-tints-2);
}
.b-text {
  text-transform: capitalize;
}
.flex-column {
  flex-direction: column;
}
.hover:hover,
.shadow {
  box-shadow: 0px 10px 18px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.31);
}
.page {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.layout,
.layout-content {
  flex: 1;
}
.layout-content {
  padding: 20px;
  overflow-y: auto;
}
/* Text Align Utilities */
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}
.text-start {
  text-align: start;
}
.text-end {
  text-align: end;
}
.text-primary {
  color: var(--main-yellow);
}
.text-accent {
  color: var(--main-off-black);
}
.text-brown {
  color: var(--yellow-tints-5) !important;
}
.text-normal {
  color: #172b4d;
}
.text-blue {
  color: #3a3a3c !important;
}
.w-24 {
  width: 24px;
}
.w-50 {
  width: 50px;
}
.w-full {
  width: "100%";
}
.aspect-1 {
  aspect-ratio: 1/1;
}
.p-l-10 {
  padding-left: 10px;
}
.p-r-20 {
  padding-right: 20px !important;
}
.full-flex {
  flex: 1;
}
.gap-10 {
  gap: 10px;
}
.text-no-wrap {
  display: block;
  white-space: nowrap;
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.max-150 {
  max-width: 10px;
}
.pointer {
  cursor: pointer;
}
/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

*::-webkit-scrollbar {
  display: none;
}

.flex-wrap {
  flex-wrap: wrap;
}

@media (max-width: 550px) {
  .mobile-column {
    flex-direction: column;
  }
  .show-tab {
    display: none;
  }
}
@media (max-width: 760px) {
  .tab-column {
    flex-direction: column;
  }
  .mobile-flex-wrap {
    flex-wrap: wrap;
  }
}

.audio-player {
  position: absolute;
  z-index: 1;
  bottom: 50px;
  right: 50px;
  background-color: red;
}
.audio-player button {
  padding: 20px;
}
