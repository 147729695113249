.support-search .btn {
  margin-left: 15px;
}
.support-search,
.text-info {
  flex: 1;
}
.text-info p {
  max-width: 500px;
  padding-right: 15px;
}
.support-card {
  flex: 1;
  background-color: var(--neutrals-lighter-yellow);
  padding: 25px 15px;
  border-radius: 8px;
  border: 1px solid var(--neutrals-light-yellow);
  min-width: 250px;
  cursor: pointer;
}
.suppor-cards {
  gap: 15px;
}
.support-card p,
.support-card h2 {
  text-align: center;
}
.support-card p {
  color: #5b5b5c;
}
.support-card h2 {
  color: var(--yellow-tints-5);
  margin: 15px 0px;
}
.support-card svg {
  width: 33px;
  height: 33px;
}
.support-header {
  width: 100%;
}
.videos {
  gap: 15px;
}

.video {
  flex: 1;
  min-width: 246px;
  border-radius: 8px;
  overflow: hidden;
  background: none;
  background-color: var(--neutrals-white);
  padding: 0px;
}
.thumbnail {
  width: 100%;
  height: 170px;
  background-color: var(--black-tints-1);
  border-radius: 8px 8px 0px 0px;
  overflow: hidden;
  position: relative;
  border: none;
  background-color: transparent;
  background: none;
}
.thumbnail .overlay {
  width: 100%;
  height: 100%;
  background-color: #00000026;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  transition: 0.3s all ease-in-out;
  opacity: 0;
}
.thumbnail:hover .overlay {
  opacity: 1;
}
.thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.video-info {
  padding: 10px;
}
.video-info span {
  color: #9c9c9d;
}
.video-info .d-flex {
  margin-top: 4px;
}
.desc-date span:first-child {
  padding-right: 10px;
  border-right: 1px solid #9c9c9d;
}
.desc-date span:last-child {
  padding-left: 10px;
}
.desc-date {
  margin-bottom: 10px;
}
.video-text-content {
  flex: 1;
  padding-right: 20px;
}
.creator-info {
  max-width: 250px;
}
ul {
  padding: 5px 15px;
}
li {
  color: #3a3a3c;
}
.display {
  background-color: #f8f9fa;
  border-radius: 8px;
  overflow: hidden;
}
.display-content {
  padding: 30px 40px;
}
.display-content p {
  font-size: 14px;
  font-weight: 400;
  color: #3a3a3c;
  margin-bottom: 10px;
  line-height: 24px;
  text-align: justify;
}
.display-content h3 {
  font-size: 16px;
  font-weight: 700;
  color: #3a3a3c;
  line-height: 24px;
}
.faq-head {
  width: 100%;
  padding: 10px 0px;
  cursor: pointer;
  background-color: transparent;
  background: none;
  outline: none;
  border: none;
  color: #3a3a3c;
}
.faq {
  border: 1px solid #ecf0ef;
  border-left: 0px;
  border-right: 0px;
  margin-bottom: 15px;
}
.faq p {
  color: #182430;
  line-height: 21px;
  margin-bottom: 10px;
}
@media (max-width: 550px) {
  .support-search {
    flex-direction: column;
  }
  .support-search .btn {
    margin-left: 0px;
    margin-top: 10px;
  }
  .video {
    max-width: 100%;
  }
}
@media (max-width: 1000px) {
  .video-text-content {
    padding-right: 0px;
  }
  .video-details {
    flex-direction: column;
  }
  .creator-info {
    max-width: 100%;
  }
}
