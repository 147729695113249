.tabs {
  padding: 10px 15px;
  background-color: var(--neutrals-light-yellow);
  padding-bottom: 0px;
}
.tabs-noborder {
  border-radius: 0;
}
.tabs button {
  padding: 10px 20px;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  background: none;
  outline: none;
  border: none;
}
.badge {
  width: 18px;
  height: 18px;
  background-color: var(--alerts-success);
  color: white !important;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.8em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tabs button span {
  color: #3a3a3c;
}
.tabs button.active-tab,
.tabs button:hover {
  background-color: #f8f9fa;
}
.tabs button.active-tab::before,
.tabs button:hover::before {
  content: "";
  width: 80%;
  position: absolute;
  bottom: 0px;
  left: 10%;
  height: 2px;
  background-color: var(--main-yellow);
}

@media (max-width: 760px) {
  .tabs {
    flex-direction: column;
  }
  .tabs button {
    width: 100%;
    text-align: left;
  }
}
