.input-container {
  position: relative;
  border: 1px solid var(--yellow-tints-2);
  border-radius: 4px;
}
.input-container input,
.input-container textarea,
.input-container select,
.plain-select {
  flex: 1;
  padding: 10px;
  border: none;
  outline: none;
  background: none;
  background-color: transparent;
  color: #3a3a3c;
  z-index: 2;
}
.input-container select,
.plain-select {
  height: 100%;
  border-radius: 0px;
  background-color: transparent;
  background: none;
  color: var(--main-yellow);
}
.input-container textarea {
  resize: none;
  height: 170px;
  /* background-color: red; */
}
.plain-select {
  padding: 5px;
  /* border: 1px solid var(--main-yellow); */
  margin-left: 5px;
}
.placeholder {
  z-index: 1;
  position: absolute;
  left: 10px;
  background-color: var(--neutrals-white);
  padding: 3px;
  transition: 0.3s all ease-in-out;
  user-select: none;
}
.input-container.focused .placeholder {
  top: -25%;
}
.input-container.focused.textarea .placeholder {
  top: -7%;
}
.input-container.focused,
.input-unset {
  border-width: 10px;
  border-width: 1.5px !important;
  box-shadow: 0px 1px 1px #f7941d40, 0px 0px 4px #f7941d40;
}
.check-box {
  width: 18px;
  background: none;
  height: 18px;
  background-color: transparent;
  border: 1px solid #d8d8d8;
  cursor: pointer;
}
.checked {
  background-color: var(--main-yellow);
  border-color: var(--main-yellow);
}
.file-picker {
  width: 100%;
  padding: 15px;
  cursor: pointer;
  border: 2px dashed var(--yellow-tints-1);
}
.file-picker img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
input[type="file"] {
  display: none;
}
.inputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.items-list {
  gap: 15px;
}
.input-unset {
  border: 1px solid var(--yellow-tints-2);
}
@media (min-width: 760px) {
  .inputs .input-container {
    width: 49% !important;
  }
}

@media (max-width: 760px) {
  .inputs,
  .items-list {
    flex-direction: column;
  }
  .inputs .input-container,
  .items-list .input-container {
    margin-bottom: 7px;
  }
}
