.wallet-action-icon {
  position: absolute;
  top: 5px;
  right: 5px;
}
@media (max-width: 760px) {
  .wallet-cards .card {
    flex: 1 !important;
  }
}
@media (max-width: 560px) {
  /* .wallet-btns .btn span {
    display: none;
  } */
}
