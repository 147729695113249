.card,
.bordered {
  padding: 20px;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  cursor: pointer;
}
.card .sp {
  display: block;
  text-align: center;
}
.card .sp:first-child {
  color: #5b5b5c;
}
.card .sp:last-child {
  color: #7b4a0e;
}
.cards {
  gap: 15px;
  flex-wrap: wrap;
}
.cards .card {
  flex: 1;
}
.card.success-light {
  border-color: var(--alerts-success);
}
.card.info-light {
  border-color: var(--alerts-info);
}
.card.warn-light {
  border-color: var(--alerts-warn);
}
.card.danger-light {
  border-color: var(--alerts-danger);
}
.card.success-light .sp:last-child {
  color: var(--alerts-success);
}
.card.info-light .sp:last-child {
  color: var(--alerts-info);
}
.card.warn-light .sp:last-child {
  color: var(--alerts-warn);
}
.card.danger-light .sp:last-child {
  color: var(--alerts-danger);
}
.card .icon {
  background-color: #fdead259;
}
