.auth-page {
  width: 100vw;
  min-height: 100vh;
  background-color: var(--neutrals-light-yellow);
  overflow-y: auto;
}
footer {
  width: 100%;
  height: 96px;
  background-color: var(--neutrals-white);
}
.form-container {
  flex: 1;
  padding: 20px;
}
.form {
  flex: 1;
}
.form-card {
  width: 100%;
  max-width: 480px;
  background-color: var(--neutrals-white);
  padding: 20px;
  border-radius: 8px;
}
.form img {
  max-width: 180px;
  max-height: 54px;
  object-fit: contain;
}
.options a {
  color: #808080;
  margin-left: 6px;
}
.options {
  margin: 10px 0px;
}
