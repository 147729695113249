nav {
  height: 100%;
  width: 230px;
  background-color: var(--yellow-tints-7);
  padding: 15px;
}
nav,
nav > * {
  transition: 0.3s all ease-in-out;
}
nav.collapsed {
  width: 80px;
}
nav .logo-cnt {
  width: 80%;
  margin-bottom: 20px;
  cursor: pointer;
  outline: none;
  background-color: transparent;
  background: none;
  border: none;
  margin-left: 10px;
}
nav .logo-cnt img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
nav.collapsed .logo-cnt {
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
}
.nav-link {
  width: 100%;
  height: 48px;
  margin-bottom: 5px;
  border-radius: 8px;
  padding: 10px;
}
.links {
  flex: 1;
  overflow-y: auto;
}
nav.collapsed .nav-link {
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav-link span {
  margin-left: 8px;
  color: var(--neutrals-white);
}
.nav-link.active-link span,
.nav-link:hover span {
  color: var(--main-off-black);
}
.nav-link.active-link,
.nav-link:hover {
  background-color: var(--main-yellow);
}
.nav-link:hover svg path {
  fill: var(--main-off-black);
}
