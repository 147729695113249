.modal {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px;
}
.m-content {
  background-color: var(--neutrals-white);
  border-radius: 8px;
  padding: 20px;
  width: 100%;
  max-width: 500px;
  z-index: 2;
  max-height: 100%;
  overflow-y: auto;
}
.modal.min-600 .m-content {
  max-width: 600px;
}
.close-modal {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: transparent;
  background: none;
  border: none;
  outline: none;
}
.modal-img img {
  border-radius: 0px !important;
  width: 100% !important;
  height: 100% !important;
}
