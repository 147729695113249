header {
  width: 100%;
  height: 80px;
  padding: 20px;
}
header h2 {
  color: #9fa6ab;
  margin-left: 10px;
  /* text-transform: capitalize; */
}

.navigate-back {
  outline: none;
  background: none;
  background-color: transparent;
  border: none;
  margin-left: -5px;
  cursor: pointer;
}
.user-info {
  height: 60px;
  padding: 10px;
  cursor: pointer;
}
.icon-notification {
  width: 35px;
  height: 35px;
  border-right: 1px solid var(--main-yellow);
}
.user-details {
  margin: 0px 10px;
}
.user-details h2 {
  color: #3a3a3c !important;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.user-info .img,
.user-info .img img {
  width: 40px;
  height: 40px;
  background-color: var(--black-tints-4);
}
@media (max-width: 540px) {
  .navigate-back {
    display: none;
  }
  .user-info {
    margin-left: auto;
  }
}
