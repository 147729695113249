button {
  cursor: pointer;
}
.btn {
  background-color: transparent;
  background: none;
  outline: none;
  border: none;
  border-radius: 8px;
}
.btn:disabled span {
  color: white !important;
}
.bg-primary {
  background-color: var(--main-yellow);
  border: 2px solid var(--main-yellow);
}
.bg-danger {
  background-color: var(--alerts-danger);
  border: 2px solid var(--alerts-danger);
}
.bg-success {
  background-color: var(--alerts-success);
  border: 2px solid var(--alerts-success);
}
.bg-info {
  background-color: var(--alerts-info);
  border: 2px solid var(--alerts-info);
}
.bg-brown {
  background-color: var(--yellow-tints-5);
  border: 2px solid var(--yellow-tints-5);
}
.bg-warn {
  background-color: #ff980033;
  border: 2px solid #ff980033;
}
.btn-outline {
  background-color: unset;
}
.bg-primary.btn-outline span {
  color: var(--main-yellow);
}
.btn span {
  margin: 0px 5px;
}
.btns .btn {
  flex: 1;
}
.btns {
  gap: 10px;
  display: flex;
  margin: 10px 0px;
}
.btn-plain {
  border: none;
  outline: none;
  background: none;
  background-color: none;
}
.btn-collaps {
  width: 100%;
  cursor: pointer;
  padding: 10px 0px;
}
.btn-add {
  text-decoration: underline;
  padding: 7px;
  margin-left: auto;
  display: block;
}
@media (min-width: 540px) {
  .btn-submit {
    max-width: 150px;
  }
}
